import React from 'react'
import "./styles/nav.css"
import "./styles/main.css"
import "./styles/crt.css"
import TitlePage from './TitlePage'
import { BrowserRouter as Router, Route,Switch} from "react-router-dom"
import Blog from './Blog'
import Post from './Post'


export default function App() {

    

    return (

        
        <div className="App crt">
            <Router>
                <Switch>
                    <Route exact path="/">
                        <TitlePage/>
                    </Route>
                    <Route path="/blog/:id">
                        <Post/>
                    </Route>
                    <Route path="/blog/">
                        <Blog/>
                    </Route>

                </Switch>
            </Router>

        </div>
    )
}
