import firebase from "firebase";
import "firebase/storage"
import "firebase/auth"
import "firebase/app"

var config = {
    apiKey: "AIzaSyDnyXiwshpXFJ9FLVXLrCYWrHgUeUeqZIw",
    authDomain: "my-blog-57ad7.firebaseapp.com",
    projectId: "my-blog-57ad7",
    storageBucket: "my-blog-57ad7.appspot.com",
    messagingSenderId: "503568285639",
    appId: "1:503568285639:web:211303f24879f6b7fee3e2",
    measurementId: "G-GSNELRFDZ3"
  };

  firebase.initializeApp(config);
  firebase.auth()

  const storage = firebase.storage();

export {config, storage, firebase as default};
