import {useState} from "react"
import github from "../img/github.png"
import linked from "../img/linked.png"

export default function Contact() {

    function open(){
        window.open('mailto:nathan.dev@iodice.co.uk?subject=Subject&body=')
    }
    return (
        <div className="contact">

            <h3>Contact</h3>
            
            <span className="email">Email: <p onClick={open}>nathan.dev@iodice.co.uk</p></span>
            <div className="social">
                <a href="https://github.com/NathanIodice"><img className="logo" src={github} alt=""/></a>
                <a href="https://www.linkedin.com/in/nathan-iodice-6a4193196/"><img className="logo" src={linked} alt=""/></a>
            </div>
        </div>
    )
}
