import React from 'react'

export default function MyWork() {
    return (
        <div className="skillsContainer">
        <h3 className="skillsTitle">My Work</h3>

        <article className="skills">

        <a className="work" href="https://steven-iodice.netlify.app/">
        <section >
                <h4 className="workTitle">Steven Iodice</h4>
                <h5 className="workTitle">Photography and Music Website/Portfolio. Created with react and using firebase for the database and image storage.</h5>
            </section>
        </a>

        <a className="work" href="https://the-map-game.netlify.app">
        <section>
                <h4 className="workTitle">The Map Game</h4>
                <h5 className="workTitle">A little game to lean countries. Made using Google Maps API and vanilla Javascript</h5>
            </section>
        </a>

        <a className="work" href="https://pointintime.netlify.app/">
        <section>
                <h4 className="workTitle">Point In Time</h4>
                <h5 className="workTitle">A weather app I built using openweather api and geolocation. Shows you the weather and time at your location</h5>
            </section>
        </a>

        </article>

    </div>
    )
}
