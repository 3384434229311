import htmlLogo from "../img/html5.png"
import css3 from "../img/css3.png"
import jsLogo from "../img/js.png"
import reactLogo from "../img/react.png"
import reduxLogo from "../img/redux.png"
import sass from "../img/sass.png"

export default function Skills() {
    return (
        <div className="skillsContainer">
            <h3 className="skillsTitle">Skills</h3>

            <article className="skills">
                <section className="skill">
                    <img src={htmlLogo} alt=""/>
                    <p>HTML 5</p>
                </section>

                <section className="skill">
                    <img src={css3} alt=""/>
                    <p>CSS 3</p>
                </section>

                <section className="skill">
                    <img src={jsLogo} alt=""/>
                    <p>JavaScript</p>
                </section>

                <section className="skill">
                    <img src={reactLogo} alt=""/>
                    <p>React</p>
                </section>
            </article>

            <h3 className="skillsTitle">Locked</h3>

            <article className="skills">
                <section className="skill locked">
                    <img src={reduxLogo} alt=""/>
                    <p>Redux</p>
                </section>

                <section className="skill locked">
                    <img src={sass} alt=""/>
                    <p>SASS</p>
                </section>

            </article>

        </div>
    )
}
