import BlogList from "./BlogList";
import useData from "./useData"
import "./styles/blog.css"
import Create from "./Create";
import {useState} from "react"
import Logo from "../img/spiderLogo.png"
import { Link } from "react-router-dom";


const Blog = () => {

    const{blog,loaded} = useData();
    const [isHidden, setIsHidden] = useState(true)
    const [nav, setNav] = useState("nav hide")

    const handleClick = () => {
        if (isHidden){
            setIsHidden(false)
            setNav("nav")
        }
        else {
            setIsHidden(true)
            setNav("nav hide")
        }
    }



    return (


        <div style={{overflow: "auto"}} className="scroll blog">


            <nav className={nav}>
            <h1 className="blogHome">Home</h1>
            <div className="logo blogLogo" ><Link to={`/`}><img on height="40px" src={Logo} alt=""/></Link></div>

                <div className="pullDown" onClick={handleClick}>
                    {isHidden?"V":"x"}
                </div>
            </nav>
            {/* <Create /> */}
            {loaded?<BlogList item={blog} hasLoaded={loaded} />:""}

            <div>
                {/* Delete on build */}
                {/* <Create/> */}
            </div>
        </div>
    );
}

export default Blog;
