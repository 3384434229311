import "./styles/Title.css"
import {useRef, useState} from "react"
import About from "./About"
import Logo from "../img/spiderLogo.png"
import Skills from "./Skills"
import MyWork from "./MyWork"
import Contact from "./Contact"
import { Link } from "react-router-dom"

export default function TitlePage() {

    const handleClick = () => {
        if (isHidden){
            setIsHidden(false)
            setNav("nav")
        }
        else {
            setIsHidden(true)
            setNav("nav hide")
        }
    }

    const scrollAbout = () => {aboutMe.current.scrollIntoView();handleClick()}
    const scrollAboutFromTitile = () => {aboutMe.current.scrollIntoView()}
    const scrollSkills = () => {skills.current.scrollIntoView();handleClick()}
    const scrollWork = () => {myWork.current.scrollIntoView();handleClick()}
    const scrollContact = () => {contact.current.scrollIntoView();handleClick()}
    const scrollTitle = () => {title.current.scrollIntoView();handleClick()}

    const aboutMe = useRef(null)
    const skills = useRef(null)
    const myWork = useRef(null)
    const contact = useRef(null)
    const title = useRef(null)

    const [isHidden, setIsHidden] = useState(true)
    const [nav, setNav] = useState("nav hide")



    return (
        <div  className="title">
            <header ref={title} onClick={scrollAboutFromTitile} className="container">
                <section className="introBox">
                <img width="20%" src={Logo} alt=""/>
                <h1 className="titleOutlined">Nathan Iodice</h1>
                <h2 className="titleNotOutlined">Web Developer</h2>
                </section>
                <div className="continue">Click To Continue</div>
            </header>

            <nav className={nav}>
                <div className="logo" onClick={scrollTitle}><img height="40px" src={Logo} alt=""/></div>
                <ul>
                    <li onClick={scrollAbout}>About Me</li>
                    <li onClick={scrollSkills}>Skills</li>
                    <li onClick={scrollWork}>My Work</li>
                    <li onClick={scrollContact}>Contact</li>
                </ul>
                <ul><Link to="/blog"><li>Blog</li></Link></ul>
                
                
                <div className="pullDown" onClick={handleClick}>
                    {isHidden?"V":"x"}
                </div>
            </nav>

            <main>

            <section ref={aboutMe} className="about container">
                <About/>
            </section>

            <section ref={skills} className="container">
                <Skills/>
            </section>

            <section ref={myWork} className="container">
                <MyWork/>
            </section>

            <section ref={contact} className="container">
                <Contact/>
            </section>
            </main>
        </div>
    )
}
