import React, { useState } from 'react'
import { Link } from 'react-router-dom';
// import "./blogList.css"

const BlogList = ({item, hasLoaded}) => {
    function removeDups(array) {
        const uniqueSet = new Set(array);
        return [...uniqueSet]
    }
    let tagList = item.map((post) => (post.tags))
    let fulltaglist = []
    tagList.forEach(e => {
        fulltaglist.push(...e)
    })
    console.log(fulltaglist)
    const [tags, settags] = useState(removeDups(fulltaglist));
    const [chosenTag, setChosenTag] = useState("");

    function handleTag(e) {
        if(chosenTag !== e.target.innerText){
            setChosenTag(e.target.innerText)
        }
        else {
            setChosenTag("")
        }
    }

    function isTag(tag){
        if(chosenTag === tag ){
            return <i className="far fa-times-circle"></i>
        }
        else {
            return  <i className=""></i>
        }
    }

    return (
        <main className="blogList">

            <h1 className="blogName">Nathans Blog</h1>

        <div className="tagList tags">
        <h2>Filter By Tag</h2>
        <ul>
        {tags.map((tag,index) => (<li key={index} onClick={handleTag} className="tags">{tag}{isTag(tag)}</li>))}
        </ul>
        </div>



            {item.slice(0).reverse().map((post,index) => (
                post.tags.includes(chosenTag)|| chosenTag=== ""?
                    <Link key={post.id}  className={`blog${index}`} to={`blog/${post.id}`}>
                    <article className="blogPost blogContainer"  key={post.id}>                                          
                            <h2 className="listTitle blogSpacing">{post.title}</h2>
                            <section className="listBody blogSpacing">
                            <p className="listDisc blogSpacing">{post.disc}</p>
                            <p className="date blogSpacing">{post.date}</p>
                            </section>
                </article>
                </Link>:""

            ))}
        </main>
    );
}

export default BlogList;
