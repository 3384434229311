import { useHistory,useParams } from "react-router-dom";
import useData from "./useData"
import firebase from "../firebase/firebase"

const Post = () => {
    const {id} = useParams();
    const {blog} = useData("blog")
    const history = useHistory();

    const post = blog.filter((word) => word.id === id)

    const handleClick = () => {
        firebase.database().ref(`blog/${id}`).remove().then(() => {
            history.push("/blog")
        })

    }

    return (
        <article className="scroll blogContainer">
            {/* <p onClick={handleClick}>DELETE</p> */}
            <p className="back"
                onClick={() => {history.push("/blog")}}
            >Back</p>
        <div className="blogPage">
            {post.map((newPost) => (
            <div key={newPost.id} className="post">
            <h1 className="blogTitle">{newPost.title}</h1>
            {newPost.tags?<ul className="tags">
            {newPost.tags.map((tag) => 
                (<li>{tag}</li>)
                    )}
            </ul>:""}
            <p className="body whiteSpace" dangerouslySetInnerHTML={{__html: newPost.body}}></p>
            {/* <button onClick={handleClick}>Delete</button> */}
            </div>
            
            ))}
        </div>
        </article>


    );
}

export default Post;
