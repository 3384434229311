

export default function About() {
    return (
        <div className="about">

            <div className="aboutContainer">

            <article className="aboutText">
            <header><h3>About Me</h3></header>
                <p>My name is Nathan Iodice. I am a self-taught Web Developer looking for a job in the front-end. I am mainly training in Front-end development, although I intend to learn as much as I can, including back-end technologies too. </p>
                <p>I am very passionate about Web Development. I enjoy a challenge and trying to work things out. I get a huge sense of achievement from solving a problem or creating something</p>
                <p>I enjoy working hard and love learning new things.</p>
            </article>

            <article className="aboutText">
            <header><h3>My Interests</h3></header>
            <ul>
                <li>Learning</li>
                <li>Gaming</li>
                <li>Nature</li>
                <li>Creative Activities</li>
                <li>Game Design</li>
            </ul>
            </article>

            </div>


        </div>
    )
}
