import {useState, useEffect} from "react"
import firebase from "../firebase/firebase"


const useData = () => {
    const [blog, setBlog] = useState([])
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        const blogRef = firebase.database().ref("blog");
        blogRef.on("value", (snapshot) => {
            let items = snapshot.val();
            let allItems = [];
    
            for (let item in items) {
                allItems.push(
                    {
                        id:item,
                        date: items[item].date,
                        title: items[item].title,
                        disc: items[item].disc,
                        body: items[item].body,
                        tags: items[item].tags
                    }
                    )
            }
            setBlog(
                allItems
            )
            setLoaded(true)
        })
    },[])

    return {
        blog, loaded
    }
}

export default useData